import { compare } from "fast-json-patch";
import pointer from "json-pointer";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import mergeWith from "lodash.mergewith";
import getFormat from "../../../helpers/getFormat";
import { resolveSchema } from "../../../helpers/resolveSchema";
import { sortProps } from "../../../helpers/sortProps";

export const patchIncludes = (path) => (patch) => patch.path.includes(pointer.compile(path));

export const getChangeHistory = (snapshotsData) => (dataPointKey, dataPointSchema) => {
  const result = snapshotsData
    .flatMap((snapshot, index) => {
      const prev = get(snapshotsData, [index - 1, "snapshot", "submission", ...dataPointKey]);
      const prevFormatted = prev ? getFormat(prev, dataPointSchema) : null;
      const current = get(snapshot, ["snapshot", "submission", ...dataPointKey]);
      const currentFormatted = current ? getFormat(current, dataPointSchema) : null;
      const isCreation = index === 0;

      if (currentFormatted === prevFormatted) {
        return [];
      }

      return {
        date: snapshot?.snapshot?.updatedAt,
        value: currentFormatted,
        ...(isCreation && { isCreation }),
      };
    })
    .reverse();

  if (result.length === 1) {
    return [];
  }

  return result;
};

export const prepareContractDetails = ({
  contractData,
  generateHistory,
  isInternalEnv,
  schemaData,
  showChangesOnly,
  snapshotData = [],
}) => {
  const submission = snapshotData?.[snapshotData.length - 1]?.snapshot?.submission || contractData.submission;
  const prevSubmission = snapshotData?.[snapshotData.length - 2]?.snapshot?.submission || {};
  const patchData = compare(prevSubmission, submission);
  const submissionSchema = cloneDeep(schemaData.properties.Draft);
  const getHistoryFromSnapshots = getChangeHistory(snapshotData);

  if (process.env.DEFAULT_PRODUCT_REF === "ah") {
    mergeWith(
      submissionSchema?.properties?.base?.properties,
      schemaData?.properties?.SubmitEndorsementForm?.properties,
    );
  }

  if (process.env.DEFAULT_PRODUCT_REF !== "ah") {
    mergeWith(
      submissionSchema?.properties?.quote?.properties,
      schemaData?.properties?.SubmitEndorsementCommercialModal?.properties?.endorsement?.properties,
    );

    mergeWith(
      submissionSchema?.properties?.quote?.properties,
      schemaData?.properties?.CancelEndorsementModal?.properties?.cancellation?.properties,
    );
  }

  resolveSchema(submissionSchema, submission);

  const sortedSectionKeys = sortProps(submissionSchema.properties).filter((sectionKey) => {
    const sectionPath = [sectionKey];
    const sectionHasValue = Boolean(get(submission, sectionPath));

    return sectionHasValue;
  });

  const filteredSectionKeys = sortedSectionKeys.filter((sectionKey) => {
    const sectionPath = [sectionKey];
    const isSectionChanged = patchData.some(patchIncludes(sectionPath));

    return showChangesOnly && isSectionChanged;
  });

  const sectionKeys = showChangesOnly ? filteredSectionKeys : sortedSectionKeys;

  return sectionKeys.map((sectionKey) => {
    const sectionSchema = submissionSchema.properties[sectionKey];
    const sectionPath = [sectionKey];
    const sortedDatapointKeys = sortProps(sectionSchema.properties);
    const filteredDatapointKeys = sortedDatapointKeys.filter((datapointKey) => {
      const datapointPath = [...sectionPath, datapointKey];
      const datapointValue = get(submission, datapointPath);
      const datapointSchema = sectionSchema.properties[datapointKey];
      const isChanged = patchData.some(patchIncludes(datapointPath));

      if ("ui:debugger" in datapointSchema && !isInternalEnv) {
        return false;
      }

      if (!datapointValue && datapointValue !== 0) {
        return false;
      }

      if (showChangesOnly && !isChanged) {
        return false;
      }

      return datapointKey;
    });

    return {
      sectionKey,
      sectionTitle: sectionSchema.title,
      data: filteredDatapointKeys.map((datapointKey) => {
        const datapointSchema = sectionSchema.properties[datapointKey];
        const datapointPath = [...sectionPath, datapointKey];
        const prevValue = get(prevSubmission, datapointPath);
        const currValue = get(submission, datapointPath);
        const datapointComponent = datapointSchema["ui:component"];

        if (datapointComponent === "InputMatrix") {
          const firstKey = Object.keys(datapointSchema.properties)[0];
          const columnsSchema = datapointSchema.properties[firstKey].properties;

          const sortedFilteredRows = sortProps(datapointSchema.properties)
            .map((key) => ({ key, value: get(submission, [...datapointPath, key]) }))
            .filter((row) => row.value || row.value === 0);

          const columns = sortProps(columnsSchema).map((column) => ({
            key: column,
            title: columnsSchema[column].title,
          }));

          return {
            datapointComponent: datapointSchema["ui:component"],
            datapointKey,
            datapointPath,
            datapointTitle: datapointSchema.title,
            columns: [{ key: "category", title: "Category" }, ...columns],
            rows: sortedFilteredRows.map((row) => {
              const title = datapointSchema?.properties?.[row.key]?.title;
              const mapped = columns.map((column) => {
                const schema = columnsSchema[column.key];
                const path = [...datapointPath, row.key, column.key];
                const changeHistory = getHistoryFromSnapshots(path, schema);

                return {
                  currFormattedValue: getFormat(row.value[column.key], schema),
                  prevFormattedValue: getFormat(prevValue?.[row.key]?.[column.key], schema),
                  ...(changeHistory.length > 0 && generateHistory && { changeHistory }),
                };
              });

              return [{ currFormattedValue: title, prevFormattedValue: title }, ...mapped];
            }),
          };
        }

        if (datapointComponent === "SectionRepeater") {
          const columnsSchema = datapointSchema?.items?.properties;
          const columns = sortProps(columnsSchema).map((column) => ({
            key: column,
            title: columnsSchema[column].title,
          }));

          const rows = currValue.map((row, rowIndex) =>
            columns.map((column) => {
              const schema = columnsSchema[column.key];
              const path = [...datapointPath, rowIndex, column.key];
              const changeHistory = getHistoryFromSnapshots(path, schema);

              return {
                currFormattedValue: getFormat(row[column.key], schema),
                prevFormattedValue: getFormat(prevValue?.[rowIndex]?.[column.key], schema),
                ...(changeHistory.length > 0 && generateHistory && { changeHistory }),
              };
            }),
          );

          return {
            datapointComponent: datapointSchema["ui:component"],
            datapointKey,
            datapointPath,
            datapointTitle: datapointSchema.title,
            columns,
            rows,
          };
        }

        const changeHistory = getHistoryFromSnapshots(datapointPath, datapointSchema);

        return {
          currValue,
          currFormattedValue: currValue || currValue === 0 ? getFormat(currValue, datapointSchema) : undefined,
          datapointKey,
          datapointPath,
          datapointTitle: datapointSchema.title,
          prevFormattedValue: prevValue || prevValue === 0 ? getFormat(prevValue, datapointSchema) : undefined,
          ...(changeHistory.length > 0 && generateHistory && { changeHistory }),
          ...(datapointComponent && { datapointComponent }),
        };
      }),
    };
  });
};
