import get from "lodash.get";
import { queryClient } from "../client/helpers/queryClient";
import { currencyFormatter, dateFormatter } from "../formatters";
import { pickSeparate } from "./pickSeparate";

const getFormat = (value, schema) => {
  if (get(schema, "ui:component") === "InputFile") {
    return value.fileName;
  }

  if (get(schema, "ui:component") === "Section") {
    return Object.keys(schema.properties)
      .map(
        (subSchemaKey) =>
          `${schema.properties[subSchemaKey].title}: ${getFormat(
            value[subSchemaKey],
            schema.properties[subSchemaKey],
          )}`,
      )
      .join("\n");
  }

  if (get(schema, "ui:component") === "InputSelectApiConnected") {
    const entityData = get(queryClient.getQueryData(["brokers"]), "data", []);

    const entity = entityData.find((e) => e.id === value) || {};
    return entity.name;
  }

  if (schema.type === "array") {
    if (Array.isArray(value)) {
      return value.join(", ");
    }
  }

  if (get(schema, "ui:component") === "AddressAutocomplete") {
    return pickSeparate(value, ["address_line_1", "address_line_2", "city", "state", "zipcode"], "\n");
  }

  if (get(schema, "ui:component") === "InputSelectCurrency") {
    return currencyFormatter(value);
  }

  if (get(schema, "ui:component") === "InputPercentage") {
    return `${value}%`;
  }

  if (get(schema, "properties.amount") && get(schema, "properties.currency")) {
    return currencyFormatter(value);
  }

  if (schema.format === "date-time") {
    return dateFormatter(value);
  }

  if (schema.type === "string" || schema.type === "number") {
    return value || "";
  }

  return value;
};

export default getFormat;
